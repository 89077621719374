<template>
  <div class="cs">
    <h1>{{ this.$t("cs.title_cs") }}</h1>
    <div class="tab_wrap">
      <div
        class="tab"
        :class="{ selected: selected_tab == 'cs' }"
        @click="this.click_tab('cs')"
      >
        {{ this.$t("cs.info") }}
      </div>
      <div
        class="tab"
        :class="{ selected: selected_tab == 'faq' }"
        @click="this.click_tab('faq')"
      >
        {{ this.$t("cs.faq") }}
      </div>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "CS",
  data: function () {
    return {
      selected_tab: "cs",
    };
  },
  mounted() {
    this.init_tab();
  },
  methods: {
    init_tab: function () {
      if (this.$route.name == "cs_info" || this.$route.name == "cs") {
        this.selected_tab = "cs";
      } else {
        this.selected_tab = "faq";
      }
    },
    click_tab: function (tab) {
      this.selected_tab = tab;

      if (tab == "cs") this.$router.push("/cs");
      else this.$router.push("/cs/faq");
    },
  },
};
</script>

<style scoped>
.cs {
  display: block;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem 1rem 3rem 1rem;
}
.cs > h1 {
  font-size: 1.6rem;
  font-weight: 700;

  padding: 0;
  margin: 0 0 2rem 0;
}
.cs > .tab_wrap {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 1rem 0 2rem 0;

  border-bottom: 1px solid #333;
}
.cs > .tab_wrap > .tab {
  width: 50%;
  padding: 1rem 1.3rem;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  background: #f4f4f4;
  cursor: pointer;
}
.cs > .tab_wrap > .tab.selected {
  background: #333;
  color: #fff;
}
</style>
